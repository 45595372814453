import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout.tsx"
import duradoc_drawing from "../../images/duradoc_drawing.jpg"
import FeedbackCard from "../../components/feedback_card.tsx"
import AboutUs from "../../components/about_us.tsx"

const DocunetProject = ({ data, location }) => {

  return (
    <Layout location={location} title="DocuNet Project">
      <div className="docunet-project-hero" />
      <div className="lg:w-1/2 mx-16 lg:ml-12 -mt-20 ">
        <div className="absolute top-80 right-36 hidden xl:block">
          <FeedbackCard location={location} />
        </div>
        <h1>DocuNet Project</h1>
        <p className="lead">
          A platform for building and exchanging business documents with
          independently verifiable integrity
        </p>
      </div>
      <div className="lg:w-1/2 mx-16 lg:mx-auto mt-16 text-black">
        <h3>The Vision</h3>
        <p>
          We believe that the people want more control over their business
          processes and data than a single centralized company can provide.
          Any/all document systems can use the DuraDoc platform and system to
          provide decentralized, portable, verifiable legal agreements with
          embedded business rules.
        </p>
      </div>
      <div className="lg:w-1/2 mx-16 lg:mx-auto mt-16 text-black">
        <h3>Active Agreements</h3>
        <p>
          ZKP based business logic and data structures can exist privately but
          are verified publicly via a neutral arbiter. This construct is not
          owned by any one company but rather created through the community of
          companies in co-opetition. These “stateless” validators require no
          private information to operate; they simply validate the public output
          of the private computation of the business logic.
        </p>
      </div>
      <div className="lg:w-1/2 mx-16 lg:mx-auto mt-16 text-black">
        <h3>Self-Control</h3>
        <p>
          For most digital legal documents, the common practice is to use the
          system of the originating party (mostly DocuSign since it is most
          popular). With documents created in the DuraDoc network, each party in
          the agreement maintains full control of the legal document files and
          associated data. Their host DuraDoc compatible service provider
          manages all the document-related activities (signing, verifying
          Identity, storage, date/time-sensitive events, etc.).
        </p>
      </div>
      <div className="lg:flex mx-16 lg:mx-12 mt-16 ">
        <div className="lg:flex-1 lg:w-1/2 py-8 md:px-16">
          <h3>Join the Network</h3>
          <p>
            Every company that joins the DuraDoc network strengthens the
            network. Since the actual function of the network is controlled by a
            non-profit (or backed by a DAO) no one company can change the rules
            or alter the network in any way. This model is similar to the
            franchise model for restaurants, you can own a Subway, but you must
            use the same branding, style of food, pricing, promotions, etc...
          </p>
        </div>
        <div className="lg:flex-1 lg:w-1/2">
          <img
            className="w-full lg:w-3/4"
            src={duradoc_drawing}
            alt="Sketch for DocuNet Project"
          />
        </div>
      </div>
      <div className="lg:w-1/2 mx-16 lg:mx-auto my-16">
        <h3>Prediction...</h3>
        <p>
          We have seen an explosion of point solutions in the enterprise space.
          This has driven the API economy and resurgence in corporate identity
          management (aka "the Okta effect"). Technologies built on Ethereum (in
          the way described here) help push those point solutions further "down
          the stack" such that the companies become features of a larger global
          ecosystem of offerings that are not tied to a particular company but
          rather the underlying execution and reconciliation framework
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="m-auto order-last mt-6 lg:order-first lg:mr-10 lg:ml-auto">
          <FeedbackCard location="projects/docunet" />
        </div>
        <div className="mt-8 ml-1 mr-10">
          <AboutUs />
        </div>
      </div>
    </Layout>
  )
}

export default DocunetProject

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
          tags
          project
        }
      }
    }
  }
`
